import React from "react";
// import NavBar from "../elements/NavBar";
import Hero from "../elements/Hero";

function Header() {
  return (
    <header>
      {/* <NavBar /> */}
      <Hero />
    </header>
  );
}

export default Header;
